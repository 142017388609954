@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CSS HEX 
--baby-pink: #ebc0c1ff;
--xanadu: #708a70ff;
--cornflower-blue: #9193faff;
--medium-slate-blue: #696cf0ff;
--lavender-web: #ddddf0ff;

?0cf574
?0cf574


?0cf574
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #2e2e2e;
  font-size: 1.4rem;
  font-weight: 400; /* review how bold it should be

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* ========= TYPOGRAPHY ========= */
h1 {
  font-size: 2rem;
  text-align: center;
  line-height: 1.8;
  font-weight: 600;
}

.hero-titles h1 {
  font-size: 2.2rem;
  text-transform: uppercase;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.8rem;
  font-weight: 400;
}

h3 {
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
}

header a {
  font-size: 1.2rem;
}

/* ======== LAYOUT PARTS ========= */
.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.page-container {
  display: flex;
  max-width: 700px;
  margin: 3em auto 2em;
  padding: 0 0.5em;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.hero-bg {
  width: 100%;
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  background-image: url("https://images.unsplash.com/photo-1515133997814-e6460be24073?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80");
  background-color: #ddddf0;
  background-blend-mode: overlay;
  background-size: cover;
}

.assistant-row {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
}

/* ======= BUTTONS  AND FIELDS ======= */
.assistant-el,
.assistant-el-disabled {
  font-size: 2rem;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0.5rem;
  padding: 0.4em 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 28%;
  transition: all 0.3s ease-in-out;
}

.assistant-el {
  border: 2px solid #696cf0;
  background-color: #ddddf0ff;
}

.assistant-el-disabled {
  border: 2px solid #9e9e9e;
  color: #9e9e9e;
  letter-spacing: 0.5rem;
  background-color: #e2e2e2;
  pointer-events: none;
  cursor: default;
}

.assistant-btn,
.assistant-btn-disabled,
.contact-form-btn {
  border: none;
  width: 31%;
  border-radius: 5px;
  padding: 0.4em 0;
  transition: all 0.3s ease-in-out;
  color: #fff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.assistant-btn,
.link-btn {
  background-color: #696cf0;
  font-weight: 700;
  box-shadow: 0.12em 0.12em 0.12em rgba(95, 95, 95, 0.2);
  transition: all 0.1s ease-in-out;
}

.assistant-btn,
.assistant-btn:focus,
.assistant-btn:hover,
.assistant-btn-disabled {
  font-size: 2rem;
}

.link-btn {
  display: inline-block;
  font-weight: 400;
  text-decoration: none;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1.5em;
  margin: 1.6em 0;
  align-self: center;
  font-size: 1.4rem;
  transition: all 0.1s ease-in-out;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.assistant-btn:focus,
.assistant-btn:hover,
.link-btn:hover
/* .link-btn:focus */ {
  background-color: #5f61f0;
  box-shadow: 0.09em 0.09em 0.09em rgba(68, 68, 68, 0.2);
  -webkit-transform: scale(0.99);
          transform: scale(0.99);
}

.assistant-btn:active,
.link-btn:active {
  box-shadow: 0.07em 0.07em 0.07em rgba(29, 29, 29, 0.2);
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.assistant-btn-disabled {
  background-color: #9e9e9e;
  font-weight: 400;
  box-shadow: 0.05em 0.05em 0.05em rgba(68, 68, 68, 0.2);
  pointer-events: none;
  cursor: default;
}

.double-square {
  width: 64%;
}

.reset-btn {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.reset-btn:hover,
.reset-btn:focus {
  font-size: 1.6rem;
}

.reset-btn span {
  font-weight: 800;
}

/* ======= INFO BLOCKS ======== */
.guide-display {
  min-height: 5em;
  margin-bottom: 1em;
  text-align: center;
  display: flex;
  align-items: center;
}

.text-info {
  margin-top: 1rem;
  text-align: center;
}

.page-header {
  font-weight: 400;
  margin-top: 2rem;
}

/* ============= HEADER AND MENU ============ */
header {
  box-shadow: 0.05rem 0.05rem 0.5rem rgb(18, 19, 54);
  background-color: #fff;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 4rem;
  justify-content: space-between;
  align-items: center;
  z-index: 998;
}

.menu {
  width: 100%;
  height: 100%;
  padding: 0 2em;
  display: flex;
  justify-content: space-between;
}

.active {
  background: #9193fa;
}

.mobile-menu-list {
  display: none;
}

.logo {
  max-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 170px;
  height: 50px;
  object-fit: cover;
}

.logo a {
  text-decoration: none;
  font-size: 1.3rem;
  color: #2e2e2e;
}

.logo a:hover,
.logo a:focus,
.logo a:active {
  color: #2e2e2e;
}

nav {
  min-width: 40%;
}

nav ul {
  list-style: none;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

nav ul li {
  flex-grow: 1;
  display: flex;
  height: 100%;
}

nav ul a {
  text-decoration: none;
  text-align: center;
  color: #2e2e2e;
  width: 100%;
  height: 100%;
  padding: 1.1em 1.3em;
  transition: ease-in-out 0.2s;
}

nav ul a:hover
/* nav ul a:focus */ {
  background-color: #696cf0;
  color: #fff;
}

.hamburger {
  display: none;
}

.hamb-line {
  width: 60px;
  height: 3px;
  background-color: #2e2e2e;
}

/* ========= FOOTER ========= */
footer {
  min-height: 12vh;
  display: flex;
  background-color: #708a70;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

/*============ HERO CONTENT ============*/
.hero-titles {
  text-align: center;
  margin-top: 2.6em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-cta-block {
  margin: 2.6em 0 1em 0;
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

.hero-cta {
  width: 45%;
}

/*============ CONTACT FORM ============*/
.contact-block {
  display: flex;
  flex-grow: 1;
  margin: 1em 0 0;
  justify-content: space-between;
  align-items: center;
}

.contact-form {
  min-width: 320px;
  display: flex;
}

.contact-form input,
.contact-form textarea {
  font-size: 1.2rem;
  padding: 0.3em;
  border: 1px solid #9e9e9e;
  background-color: #f4f4fa;
  border-radius: 5px;
}

.contact-form textarea {
  font-family: "Open Sans", sans-serif;
  resize: none;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline-color: #696cf0;
}

.contact-form label {
  font-size: 1rem;
  margin: 1em 0 0.2em;
}

.contact-form-list {
  list-style: none;
  width: 100%;
}

.contact-form-list li {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.vertical-spread-line {
  width: 2px;
  border-radius: 5px;
  align-self: stretch;
  background-color: #ddddf0;
  margin: 0 3em;
}

.form-placeholder {
  width: 60%;
}

.contacts-placeholder {
  margin-top: 2em;
  width: 30%;
  font-size: 1.2rem;
}

.contact-container {
  display: flex;
  flex-direction: column;
  /*   align-items: center; */
  margin-top: 2em;
}

.contact-container svg {
  width: 20%;
}
.contact-container p {
  width: 75%;
  /*   margin-left: 1em; */
}

.contacts-placeholder a {
  margin-bottom: 2rem;
  color: #696cf0;
  text-decoration: none;
}

/*============ VIDEO GUIDE ============*/

.video-responsive {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /*  width: 600px;
  height: 340px; */
}

.video-responsive iframe {
  max-width: 600px;
  max-height: 340px;
}

.guide-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.steps-header {
  margin-top: 1.6em;
  font-size: 1.6rem;
}

.guide-block-item {
  margin-top: 2rem;
  padding: 0.8em;
  width: 30%;
  display: flex;
  background-color: #ddddf0;
  border-radius: 5px;
  flex-direction: column;
}

.guide-block-item-img {
  text-align: center;
  font-size: 5rem;
  color: #9193fa;
}
.step-number {
  font-size: 5rem;
  font-weight: 900;
}
.guide-block-item-desc {
  margin-top: 0.8em;
  font-size: 1.2rem;
  text-align: center;
}

/*============== Spinner ===============*/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  align-self: center;
  margin: 3em;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 6px solid #cef;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #708a70 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*================ MOBILE VERSION =================*/

@media (max-width: 700px) {
  .menu {
    padding: 0;
  }

  .logo {
    margin: 0 1.6em;
    padding: 0.6em;
  }
  nav {
    min-width: 0;
    display: flex;
    align-items: center;
  }

  .hamburger {
    display: flex;
    padding: 0 1em;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-self: stretch;
    min-height: 3em;
  }

  .hamburger:hover {
    background-color: #696cf0;
  }

  .hamb-line-hovered {
    background-color: #fff;
  }

  .nav-list {
    display: none;
  }

  .mobile-menu-list {
    position: absolute;
    top: 6rem;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 50vh;
    background-color: #fff;
    justify-content: center;
    box-shadow: 0.12em 0.12em 0.12em rgba(95, 95, 95, 0.2);
  }

  .mobile-menu-list li {
    width: 100%;
    height: auto;
    flex-grow: 0;
    background-color: #fff;
  }

  .contact-block {
    flex-direction: column;
    align-items: flex-start;
  }

  .vertical-spread-line {
    display: none;
  }

  .video-responsive iframe {
    width: 90%;
  }

  .guide-block-item {
    width: 48%;
  }

  .hero-cta-block {
    flex-direction: column;
    margin-top: 1em;
    padding-bottom: 1em;
  }

  .hero-cta {
    margin-top: 2em;
    min-width: 80%;
  }
}

